<template>
  <div class="content-wrapper">
    <div class="ml-4 d-flex align-items-center">
      <i class="nav-icon fa-2x fas fa-exclamation-circle text-muted"></i>
      <h2 class="text-muted ml-2 mt-2">Novidades</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col ml-5 mt-4">
          <label for="title">Título</label>
          <input
            type="text"
            class="form-control inputStyle"
            v-model="title"
            maxlength="50"
            placeholder="Digite o título da melhoria"
          />
          <p>Máximo ({{ title.length }}/50) caracteres</p>
          <label for="description">Descrição</label>
          <textarea
            class="form-control inputStyleDescription"
            v-model="description"
            type="text"
            maxlength="500"
            placeholder="Digite a descrição..."
          ></textarea>
          <p>Máximo ({{ description.length }}/500) caracteres</p>
          <label for="imagem">Imagem</label>
          <br />
          <input type="file" @change="handleImageUpload" accept="image/*" />
          <p class="mt-2">Tamanho ideal (660x380px)</p>
          <img v-if="!imageBase64" :src="imageBase64" class="imgSize" />
          <label class="mt-4" for="redirect">Redirecionamento</label>
          <input
            class="form-control inputStyle"
            v-model="redirect"
            placeholder="http://www.tallos..."
          />
          <div class="d-flex justify-content-center mt-5">
            <router-link to="/admin/news">
              <button class="mr-3 btn btn-second cancelButton">
                Cancelar
              </button>
            </router-link>
            <button class="ml-3 btn btn-primary sendButton" @click="buttonSend">
              Enviar
            </button>
          </div>
        </div>
        <div class="col">
          <h4 class="boldStyle">Preview</h4>
          <div class="cardPreview">
            <div class="imgRealTime">
              <img
                v-if="imageBase64"
                :src="imageUrl"
                alt="Imagem em tempo real"
                class="imgSize"
              />
              <div v-if="!imageBase64" class="imgSizeEmpty"></div>
            </div>
            <div>
              <p class="newInformation mt-3 ml-3">
                <i class="nav-icon fas fa-exclamation-circle mr-2"></i>Novo
              </p>
            </div>
            <div>
              <h5 class="boldStyle ml-3">{{ title }}</h5>
              <p class="ml-3 mr-2">{{ description }}</p>
              <div
                class="d-flex justify-content-between align-items-center p-2 mb-2"
              >
                <div>
                  <button class="btn btn-second disabled">
                    <i class="fas fa-angle-left"></i> Anterior
                  </button>
                  <button class="btn btn-second disabled">
                    Próximo <i class="fas fa-angle-right"></i>
                  </button>
                </div>
                <div>
                  <button class="btn btn-second mr-2 moreInformations disabled">
                    Saiba mais
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      title: "",
      description: "",
      redirect: "",
      imageUrl: null,
      imageBase64: null,
    };
  },
  methods: {
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageUrl = reader.result;
          const readerResult = reader.result;
          const base64String = readerResult.split(",")[1];
          this.imageBase64 = base64String;
        };
        reader.readAsDataURL(file);
      }
    },
    buttonSend() {
      const vuexData = JSON.parse(localStorage.getItem("vuex"));
      const token = vuexData.tokenJWT;
      const url = `${process.env.VUE_APP_URL_UPDATES_API}/news`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const data = {
        title: this.title,
        content: this.description,
        fileBase64: this.imageBase64 || null,
        helpLink: this.redirect || null,
      };
      if (!this.title || !this.description) {
        this.$toasted.global.defaultError({
          msg: "Campos de Título e Descrição obrigatórios",
        });
        return;
      }
      axios
        .post(url, data, config, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.title = "";
          this.description = "";
          this.redirect = "";
          this.imageBase64 = "";
          this.imageUrl = "";
          this.$toasted.global.defaultSuccess({
            msg: "Novidade criada com sucesso!",
          });
        })
        .catch((error) => {
          console.error("Error sending image:", error);
          this.$toasted.global.defaultError({
            msg: "Erro ao cadastrar novidade.",
          });
        });
    },
  },
};
</script>

<style>
.boldStyle {
  font-weight: bold;
}
.inputStyle {
  width: 80%;
}

.inputStyleDescription {
  width: 80%;
  height: 20%;
}

.newInformation {
  background-color: #009acc;
  height: 30px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 20px;
}

.cancelButton {
  width: 120px;
  height: 40px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #6c757d;
  color: #6c757d;
}

.cardPreview {
  border: 1px solid #ddd;
  border-radius: 10px;
}

.sendButton {
  width: 120px;
  height: 40px;
  border-radius: 20px;
  background-color: #009acc;
}

.imgSizeEmpty {
  width: 100%;
  height: 300px;
  background-color: #d5d6d6;
  border-radius: 10px 10px 0 0;
}

.imgRealTime {
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
}

.imgSize {
  display: flex;
  justify-content: center;
  max-width: 100%;
  height: auto;
  max-height: 380px;
  background-color: #d5d6d6;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
}

.moreInformations {
  background-color: #002d6e;
  height: 30px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 20px;
}
</style>
